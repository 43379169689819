import { t } from 'i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useQuery, useMutation } from 'react-query';
import React, { useContext, useState } from 'react';
import { IonButton, IonHeader, IonImg, useIonViewWillEnter } from '@ionic/react';

import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import {
  Vote,
  Toast,
  Loader,
  Button,
  Divider,
  TeamNews,
  OwnersCard,
  LevelProgressBar,
  MarketCarouselSection,
} from '../components';
import { useLikingCardId } from '../hooks/useLikingCardId';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { useMembershipExpiration } from '../hooks/useMembershipExpiration';
import { fetchVotes, likeMarketCard, fetchUserWatch, fetchMarketList } from '../services';

const HomeWrapper = styled.div<{ isTicketButton?: boolean; isCardExpiringSoon?: boolean }>`
  margin: 0 20px;
  .footer-wrap {
    padding: 33px 0px 33px 0px;
  }
  .vote-wrap {
    padding-top: 42px;
    position: relative;

    h1 {
      position: absolute;
      top: 50%;
      z-index: 10;
      width: 100%;
      text-align: center;
      font-weight: 800;
      font-size: 30px;
      transform: rotate(-35deg);
    }
  }

  .section-divider {
    margin-top: 2.6rem;
  }
  .rank-up {
    margin: 0em 0.8em;
    margin-top: 1.6em;
    margin-bottom: ${(props) => (props.isCardExpiringSoon ? 0 : '2.6em')};
  }

  .membership-button {
    margin-top: ${(props) => (props.isTicketButton ? '1em' : '1.6em')};
    margin-bottom: 2.6em;
    font-size: 15px;
    height: 50px;
    background-color: #b80629;
    border-radius: 2rem;

    :hover {
      background-color: #c5072d;
    }

    @media (max-width: 380px) {
      font-size: 14px;
    }
  }
`;

const LevelBlock = styled.div<any>`
  border-radius: 50px;
  padding: 12px 18px;
  margin: 37px 0;
  ion-header {
    font-size: 2rem;
    font-weight: 800;
    color: ${(props) => (props?.color ? props.color : 'black')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ::after {
      display: none;
    }
    .scan-wrap {
      float: right;
      display: flex;
      align-items: center;
      color: #4d4d4d;
      font-size: 13px;
      font-weight: 500;
      line-height: 19px;
      ion-button {
        margin-left: 15px;
        ::part(native) {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        ion-img {
          ::part(image) {
            width: 30px;
          }
        }
      }
    }
  }
  .level-up-button {
    margin: 27px 0 20px;
  }
  .other-buttons {
    margin: 0 0 20px;

    ion-spinner {
      height: 12px;
      width: 12px;
    }
  }
  .about-level-up-button {
    color: ${(props) => props.color};
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    display: flex;
    --background-hover: var(--ion-color-primary);
    ::part(native) {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
    .triangle {
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 20px solid #eee;
      filter: drop-shadow(-2px -2px 1px #fbfbfb);
      position: relative;
      margin-right: 9px;
      ::after {
        content: '';
        filter: drop-shadow(2px 2px 1px #d0d0d0);
        position: absolute;
        top: -12px;
        left: -20px;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 20px solid #eee;
      }
    }
  }
`;

const TopPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [showToast, setShowToast] = useToast(false);
  const { teamDetails, totalTeamCoin, setQrCode, isLoading, userProfileData, status, update } =
    useContext(UserContext);
  const currentLevel = teamDetails?.level;
  const levelUpto = status?.levelTo;

  const VALUE =
    (+totalTeamCoin / teamDetails?.requiredToken) * 100 >= 100
      ? 100
      : (+totalTeamCoin / teamDetails?.requiredToken) * 100;

  const { mutate } = useMutation(fetchUserWatch, {
    onSuccess: ({ data }) => {
      setQrCode(data?.qr);
      setLoading(false);
      history.push('/qr-code');
    },
    onError: (err: any) => {
      setLoading(false);
      setError(err.response.data.message);
      setShowToast(true);
    },
  });

  const QrCodeHandler = () => {
    setLoading(true);
    mutate({
      teamId: teamDetails?.team.id,
      userId: userProfileData?.id,
      gameId: 'd6d09322-c92a-4067-b2ec-710eb7e176ca',
    });
  };

  const {
    data: votesData,
    isLoading: voteLoading,
    isFetching: voteFetching,
  } = useQuery(['fetchVotes', 1, userProfileData?.id, false], fetchVotes, {
    enabled: !!userProfileData?.id,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: (response) => {
      return response?.data?.votes?.slice(0, 3);
    },
  });

  // NFT Market API
  const {
    data: marketList,
    isFetching: loadingMarketList,
    refetch,
  } = useQuery(['fetchMarketList', 1, 'desc', 'createdAt'], fetchMarketList, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  // Like Market Card API
  const { isExpired, isCardExpiringSoon } = useMembershipExpiration();
  const { likingCardId, setLikingCardId } = useLikingCardId();
  const { mutate: likeCard, isLoading: isLiking } = useMutation(likeMarketCard);

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (userProfileData?.id)
      likeCard({ nftMasterId, userId: userProfileData?.id, teamId: teamId! });
  };

  useIonViewWillEnter(() => {
    async function fetch() {
      await update();
    }
    fetch();
    // Refetch to get new likes
    refetch();
  }, []);

  useIonViewWillEnter(() => {
    if (isExpired) {
      history.push('/membership-card-info');
    }
  }, [isExpired]);

  return (
    <Wrapper authenticated={true}>
      {showToast && <Toast message={error} type={'Error'} />}

      <Loader isLoading={isLoading} className="home-loader">
        <HomeWrapper
          isTicketButton={!!status?.ticketSalesURL}
          isCardExpiringSoon={isCardExpiringSoon}
        >
          <OwnersCard name={userProfileData?.username} id="home" />
          {status?.ticketSalesURL && (
            <Button
              className="rank-up"
              size="large"
              onClick={() => window.open(atob(status?.ticketSalesURL), '_blank')}
              color={teamDetails?.team?.teamColor}
            >
              {t('To ticket sales page')}
            </Button>
          )}
          {isCardExpiringSoon && (
            <Button
              size="large"
              className="rank-up membership-button"
              color="#fff"
              onClick={() => history.push('/membership-card-info')}
            >
              {t('Membership card info')}
            </Button>
          )}
          {/* <div className="top-card-wrap">
            <TopCard
              image={teamDetails?.team?.teamImagePath}
              logo={teamDetails?.team?.logoImagePath}
            />
          </div> */}
          <LevelBlock className="neomorphs-shadow-out" color={teamDetails?.team?.teamColor}>
            <IonHeader>
              Level
              <div className="scan-wrap">
                QR
                <Button onClick={() => QrCodeHandler()} disabled={loading}>
                  <Loader isLoading={loading}>
                    <IonImg src="/assets/images/qr-scan.png" alt="QR Scan" />
                  </Loader>
                </Button>
              </div>
            </IonHeader>
            <LevelProgressBar
              level={teamDetails?.level}
              value={VALUE}
              totalPoint={teamDetails?.requiredToken}
              receivedPoint={+totalTeamCoin}
              color={teamDetails?.team?.teamColor}
            />
            <Button
              className="level-up-button"
              // disabled // prod-time
              disabled={teamDetails?.level >= 50}
              color={teamDetails?.team?.teamColor}
              size="large"
              onClick={() => history.push('/level-up')}
            >
              {t('Level UP')}
            </Button>
            {!(currentLevel < levelUpto) && (
              <Button
                className="other-buttons"
                size="large"
                onClick={() => history.push('/status-up')}
                color={teamDetails?.team?.teamColor}
              >
                ステータスアップする
              </Button>
            )}
            <Button
              className="other-buttons"
              size="large"
              disabled={loading}
              onClick={() => QrCodeHandler()}
              color={teamDetails?.team?.teamColor}
            >
              <Loader isLoading={loading}>{t('Earn visiting points')}</Loader>
            </Button>
            <IonButton
              className="about-level-up-button"
              onClick={() => history.push({ pathname: '/how-to-use', search: '?about-level' })}
            >
              <span className="triangle"></span>
              {t('About Point / level UP')}
            </IonButton>
          </LevelBlock>
          <Divider className="divider-1" />
          {teamDetails?.team.newsId && (
            <>
              <TeamNews color={teamDetails?.team?.teamColor} />
              <Divider className="divider-1" />
            </>
          )}

          {votesData && votesData?.length ? (
            <>
              <div className="vote-wrap">
                <Vote
                  data={votesData}
                  color={teamDetails?.team?.teamColor}
                  avatar={teamDetails?.team?.teamImagePath}
                  isLoading={voteLoading || voteFetching}
                />
              </div>
              <Divider className="section-divider" />
            </>
          ) : (
            ''
          )}
        </HomeWrapper>

        <MarketCarouselSection
          color={teamDetails?.team?.teamColor}
          data={marketList?.data?.utilityNfts?.map((d) => {
            // after randomUtilityNfts has items field
            /* let finalImage: string = '';
          if (d?.isRandomTemplate === true) {
            finalImage = d?.randomUtilityNfts?.items![0].templateFileUrl ?? '';
          } else {
            finalImage = d?.templateFileUrl ?? '';
          } */
            return {
              teamName: d?.team?.teamName,
              teamLogo: d?.team?.primaryLogoImagePath,
              // after randomUtilityNfts has items field
              /* image: finalImage, */
              image: d?.templateFileUrl,
              title: d?.cardName,
              price: `${d?.price?.toLocaleString()}円`,
              quantity: `${d?.currentSoldNumber?.toString()}/${d?.maxSalesNumber?.toString()}`,
              id: d?.id,
              likesNumber: d?.favorites?.items ? d?.favorites?.items?.length : 0,
              favorites: d?.favorites?.items,
              teamId: d?.team?.id,
            };
          })}
          loading={loadingMarketList}
          onLikeClick={handleLike}
          isLiking={isLiking}
          likingCardId={likingCardId}
          setLikingCardId={setLikingCardId}
        />
      </Loader>
    </Wrapper>
  );
};

export default PrivateRoute(TopPage);
